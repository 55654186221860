// deobfuscation.service.ts
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class UtilityService {
    decodedAndDeobfuscatedNumber: string;
    constructor() {
    }

    deobfuscateNumber(obfuscatedNumber: string): string {
        // Secret key
        const secretKey = '7Bx9eKp2XNtFzWQaR3jUvTgV4mL6sYq8Sx1nEiHrA'; // 64-bit secret key
        // Define the reverse character substitution map
        const deobfuscationMap: { [key: string]: string } = {
            'a': '0',
            'b': '1',
            'c': '2',
            'd': '3',
            'x': '4',
            'f': '5',
            'z': '6',
            'h': '7',
            'i': '8',
            'j': '9'
        };

        // Decode the encoded and obfuscated number
        const decodedObfuscatedBytes = atob(obfuscatedNumber); // Base64 decoding
        //    const deobfuscatedWithSecretKey = String.fromCharCode.apply(null, new Uint8Array(decodedObfuscatedBytes));

        // Separate the obfuscated number and secret key
        const indexOfSecretKey = decodedObfuscatedBytes.lastIndexOf(secretKey);
        const obfuscatedNumberWithoutSecret = decodedObfuscatedBytes.substring(0, indexOfSecretKey);

        // Deobfuscate the number
        let decodedAndDeobfuscatedNumber = '';
        for (const obfuscatedDigit of obfuscatedNumberWithoutSecret) {
            if (deobfuscationMap[obfuscatedDigit]) {
                decodedAndDeobfuscatedNumber += deobfuscationMap[obfuscatedDigit];
            } else {
                decodedAndDeobfuscatedNumber += obfuscatedDigit;
            }
        }

        return decodedAndDeobfuscatedNumber;
    }

    generateAlphaNumericCode(length = 10, prefix) {
        const charsetCapital = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const charsetNumbers = '0123456789';
        let result = '';
        // Ensure the first two characters are capital letters
        if(prefix) {
            result += prefix;
        } else {
            for (let i = 0; i < 2; i++) {
                const randomIndex = Math.floor(Math.random() * charsetCapital.length);
                result += charsetCapital[randomIndex];
            }
        }

        // Generate the remaining characters as numbers
        const remainingLength = length - 2;
        for (let i = 0; i < remainingLength; i++) {
            const randomIndex = Math.floor(Math.random() * charsetNumbers.length);
            result += charsetNumbers[randomIndex];
        }

        return result;
    }
    
    convertToReadableDate(yyyymm) {
        // Extract year and month from the input string
        const year = yyyymm.substring(0, 4);
        const month = parseInt(yyyymm.substring(4, 6), 10);
      
        // Array to map month numbers to month names
        const monthNames = [
          'January', 'February', 'March', 'April', 'May', 'June',
          'July', 'August', 'September', 'October', 'November', 'December'
        ];
      
        // Get the month name
        const monthName = monthNames[month - 1];
      
        // Construct the readable date string
        const readableDate = `${monthName}-${year}`;
      
        return readableDate;
      }
      
}
