export class ClsMonthWiseLeger{

    EMPLOYEE_NAME: string = ''
    F_H_NAME: string = ''
    EMAIL: string = ''
    CREATEDBY: string = ''
    CREATEDON: string = ''
    CANCEL: string = ''
    ACTIVE: string = ''
    DOB: string = ''
    DDO: string = ''
    UNIT: string = ''
    MONTH: string = ''
    SALARY_MONTH: string = ''
    TRANS_MODE: string = ''
    PREMIUMUSUAL : string = ''
    INCREMENTALPREMIUM: string = ''
    ARREARSUSUAL: string = ''
    LOAN_INSTALMENT: string = ''
    LOAN_INTEREST  : string = ''
    SERVICETAX : string = ''
   


    
    SALAEYDATE : string = ''
    VOUCHERDATE : string = ''
    TVNO : string = ''
    TOTALPREMIUM : string = ''
    TRANSTYPE : string = ''
    OFFICE : string = ''
    LOAN : string = ''
    CLAIM : string = ''
    REFUND_AMOUNT: string = ''
}