export class ClsMenu {

  TB_MST_MENUID : number
  MENU_NAME : string= ''
  MENU_TITLE  : string= ''
  PARENT_ID : string= ''
  MENU_LEVEL : string= ''
  DISPLAY_ORDER : string= ''
  IMAGE_CLASS: string= ''
  PAGE_URL  : string= ''
  CREATEDBY  : string= ''
  CREATEDON  : string= ''
  MODIFIEDBY  : string= ''
  MODIFIEDON  : string= ''
  ACTIVE_STATUS : string= ''
  IS_PARENT: string= ''
}
export class ClsBonusRate {
TB_SI_MST_BONUSRATEID: number
CANCEL : string= ''
MODIFIEDBY : string= ''
MODIFIEDON : string= ''
CREATEDBY : string= ''
CREATEDON : string= ''
FOR_YEAR : string= ''
RATE_OF_BONUS : string= ''
ORDER_DATE : string= ''
EFFECTIVE_FROM : string= ''
EFFECTIVE_TO : string= ''
RATE_OF_TERMINAL : string= ''
TERMINAL_BONUS : string= ''
ACTIVE : string= ''
}


