export class ClsSIDashboard{

    EMPLOYEE_NAME: string = ''
    F_H_NAME: string = ''
    EMAIL: string = ''
    CREATEDBY: string = ''
    CREATEDON: string = ''
    CANCEL: string = ''
    ACTIVE: string = ''
    DOB: string = ''
    DDO: string = ''
    UNIT: string = ''
    DESIGNATION: string = ''
    A: string = ''
    TOTALPREMIUM: string = ''
    FINYEAR: string = ''
    EMPLOYEEID: string = ''
    CPFTOGPF: string = ''
    OPBAL: string = ''
    APRIL: string = ''
    MAY: string = ''
    JUNE: string = ''
    JULY: string = ''
    AUG: string = ''
    SEP: string = ''
    OCT: string = ''
    NOV: string = ''
    DEC: string = ''
    JAN: string = ''
    FEB: string = ''
    MAR : string = ''
    TOTAL_OF_REC_THIS_YR: string = ''
    STOTAL: string = ''
    WITHDRAWALS: string = ''
    CLAIMS : string = ''
    INTR: string = ''
    CL_BAL: string = ''
    BASIC_PAY: string = ''
    TVDATE  : string = ''
    TVNO : string = ''
    SALARY_DATE: string = ''
    OFFICE: string = ''
    SI_NO_OF_CONTRACT: string = ''
    SI_CURRENT_PREMIUM: string = ''
    SI_POLICY_NO: string = ''
    LOAN: string = ''
    CLAIM: string = ''
    REFUND_AMOUNT: string = ''
    
}