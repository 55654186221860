export class ClsGpfDashboard{

    EMPLOYEE_NAME: string = ''
    F_H_NAME: string = ''
    EMAIL: string = ''
    CREATEDBY: string = ''
    CREATEDON: string = ''
    CANCEL: string = ''
    ACTIVE: string = ''
    DOB: string = ''
    DDO: string = ''
    UNIT: string = ''
    DESIGNATION: string = ''
    A: string = ''
    B: string = ''
    FY: string = ''
    EMPLOYEEID: string = ''
    CPFTOGPF: string = ''
    OPBAL: string = ''
    APRIL: string = ''
    MAY: string = ''
    JUNE: string = ''
    JULY: string = ''
    AUG: string = ''
    SEP: string = ''
    OCT: string = ''
    NOV: string = ''
    DEC: string = ''
    JAN: string = ''
    FEB: string = ''
    MAR : string = ''
    TOTAL_OF_REC_THIS_YR: string = ''
    STOTAL: string = ''
    WITHDRAWALS: string = ''
    CLAIMS : string = ''
    INTR: string = ''
    CL_BAL: string = ''
    BASIC_PAY: string = ''
    TVDATE  : string = ''
    TVNO : string = ''
    SALARY_DATE: string = ''
    OFFICE: string = ''
    GPF_ACCOUNT_NO:string=''
    GPF_DEDUCTIONS:string=''
    GPF_WITHDRAWAL:string=''
    

}

export class ClsSIClaimdetailsPrintModel {

    AB:string
    AMOUNT_IN_WORD:string
    APP_DATE:string
    APP_NO:string
    ASA:string
    CEASE_AB:string
    CEASE_ASA:string
    CEASE_RB:string
    CEASE_SA:string
    CLAIM_DATE:string
    CLAIM_TYPE:string
    DDO:string
    DEPARTMENT:string
    DUPLICATE_POLICYFEE:string
    EMPLOYEE_ID:string
    EXCESS_LOAN:string
    EXCESS_LOAN_INTEREST:string
    EXCESS_PREMIUM:string
    EXCESS_PREMIUM_INTEREST:string
    IB:string
    NAME:string
    OTHER_DUE:string
    OTHER_INTEREST_DUE:string
    POLICY:string
    PREMIUM_GAP:string
    PREMIUM_GAP_INT:string
    PREMIUM_SHORT:string
    PREMIUM_SHORT_INT:string
    PREVIOUS_UNSETTLED_LOAN:string
    PREVIOUS_UNSETTLED_LOAN_INTR:string
    RB:string
    SA:string
    TB:string
    TOTAL_ADJUST_DTLS:string
    TOTAL_CLAIMAMOUNT:string
    TOTAL_PAY_DTLS:string
    UNIT:string
    UNSETTLED_LOANS:string
    UNSETTLED_LOAN_INTEREST:string
    TOTAL_SURR_DTLS:string
    }