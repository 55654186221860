import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'numberCommaMask' })
export class NumberCommaMaskPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return '';
    }

    let data = value.split(".")
    if(data[1]) {
      return parseFloat(data[0]).toLocaleString('en-IN') + "." + data[1] ;
    } else {
      return parseFloat(data[0]).toLocaleString('en-IN');
    }
  }
}
