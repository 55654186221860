import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
// Pipes

import { NumberCommaMaskPipe } from './pipes/number-comma-mask.pipe';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
  ],
  declarations: [
    NumberCommaMaskPipe
  ],
  exports: [
    NumberCommaMaskPipe
  ]
})
export class MainPipe { }
